body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.error{
  color:red;
}
.ml-auto {
    margin-right: 0px !important;
}
.alert {
    position: relative;
    padding-right: 30px;
}

.alert i {
    position: absolute;
    right: 10px;
    top: 10px;
}

.text-color{
    color: blue;
}
.loader-wrapper {
    background: rgba(255, 255, 255, 0.72);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999 !important;
    padding: 300px 47%;
}
.linear-background {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 104px;
    /* height: 338px; */
    position: relative;
    overflow: hidden;
}

.ld-ext-right>.ld {
    top: 50%;
    left: auto;
    right: 1em;
}

.active_tab{
    border-color: #467fcf;
    color: #467fcf;
    background: transparent;
}
.actions a {
padding: 5px;
background: #467fcf;
margin: 2px;
border-radius: 4px;
width: 30px;
display: inline-block;
height: 30px;
text-align: center;
color: #fff;
}

form {
width: 100%;
}

button + button {
margin-left: 20px !important;
}

.header.collapse {
margin-bottom: 20px;
}

.escalateButton {
    background: #296ffa;
    border-color: #296ffa !important;
}

.tableMargin {
    margin-top: 45px;
}

.disableFlexWrap {
    flex-wrap: unset;
}

.cursorPointer {
    cursor: pointer;
}

.BackgroundBlur::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.5;
    z-index: 11;
}

.BackgroundBlur button:hover {
    background: #eeeeee !important;
}

.spaceBetweenContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.capitalizeText {
    text-transform: capitalize;
}
